/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.banner .fa-bars').on('click', function(){
    $(this).hide();
    $(this).siblings('.fa-times').show();
    $(this).siblings('.menu-main-menu-container').slideToggle();
    // Dynamically sets top margin of mobile menu
  });
  $('.banner .fa-times').on('click', function(){
    $(this).hide();
    $(this).siblings('.fa-bars').show();
    $(this).siblings('.menu-main-menu-container').slideToggle();
  });

    $('.menu-item-has-children').append("<span class='expander'><span class='fa fa-chevron-down'></span></span>");

    $(document).on("click", ".expander", function () {

        if($(this).siblings('.sub-menu').css('display') === 'none')
        {
            $(this).siblings('.sub-menu').css('display', 'block');
            $(this).css('color', '#fdca01');
            $(this).siblings('a').css('background-color', 'black');
            $(this).siblings('a').css('color', '#fdca01');
        }
        else
        {
            $(this).siblings('.sub-menu').css('display', 'none');
            $(this).css('color', 'black');
            $(this).siblings('a').css('background-color', '#fdca01');
            $(this).siblings('a').css('color', 'black');
        }
    });
    // $('.menu-item > a').focusout(function(){
    //   console.log(123);
    //     $(this).find('.sub-menu').css("display", "none");
    // });

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            $("#scroll-top").show();
        } else {
            $("#scroll-top").hide();
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    $("#scroll-top").on('click', function() {
        //document.body.scrollTop = 0; // For Safari
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

    $('.owl-carousel').owlCarousel({
      items: 1,
      loop:true,
      nav:false,
      dots:true,
      autoplay:true,
      autoplayTimeout:4000,
    });

    setTimeout(
      function() {
        $('.owl-carousel').trigger('refresh.owl.carousel');
      }, 
    50);

})(jQuery); // Fully reference jQuery after this point.
